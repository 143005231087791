import CompanyPage from 'Views/company/CompanyPage'
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { CompanyPageQuery } from 'Typings/generated-gatsby-types'

export default function Company(props: PageProps<CompanyPageQuery>) {
  return <CompanyPage data={props.data?.strapiCompanyPage} path={props.path} />
}

export const query = graphql`
  query($language: String!) {
    strapiCompanyPage(locale: { eq: $language }) {
      seo {
        meta_title
        meta_description
        meta_image {
          localFile {
            publicURL
          }
        }
      }
      heading
      sub_heading
      sections {
        ...DynamicSections
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
